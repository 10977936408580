import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import * as React from "react";
import { dynaError, } from "dyna-error";
import { Button } from "../Button";
export class ErrorBoundary extends React.Component {
    // Resource: https://reactjs.org/docs/error-boundaries.html
    constructor(props) {
        super(props);
        this.handleRefreshClick = () => window.location.reload();
        this.state = {
            error: null,
            errorInfo: null,
        };
    }
    componentDidCatch(error, errorInfo) {
        const { consoleError = true, onError, } = this.props;
        this.setState({
            error: dynaError(error),
            errorInfo: errorInfo,
        });
        onError === null || onError === void 0 ? void 0 : onError(error, errorInfo);
        if (consoleError) {
            console.error('ErrorBoundary error:', error.message, {
                error,
                errorInfo,
            });
        }
    }
    render() {
        const { children, onErrorRender, } = this.props;
        return this.state.error
            ? ((onErrorRender === null || onErrorRender === void 0 ? void 0 : onErrorRender(this.state.error)) ||
                _jsxs(_Fragment, { children: [_jsx("h5", { style: { color: 'red' }, children: "Something went wrong. Try refreshing the page." }), _jsx(Button, { onClick: this.handleRefreshClick, children: "Refresh" })] }))
            : children;
    }
}
