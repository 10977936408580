import { jsx as _jsx } from "react/jsx-runtime";
import { isMac } from "utils-library/dist/web";
import { Box } from "../Box";
import { useBreakpointDevice } from "../useBreakpointDevice";
export var EButtonBarAlign;
(function (EButtonBarAlign) {
    EButtonBarAlign["LEFT"] = "flex-start";
    EButtonBarAlign["CENTER"] = "center";
    EButtonBarAlign["RIGHT"] = "flex-end";
})(EButtonBarAlign || (EButtonBarAlign = {}));
export const ButtonBar = (props) => {
    const { sx = {}, inline = false, show = true, align = EButtonBarAlign.LEFT, spacing = 1, noHorizontalSpace = false, noVerticalSpace = false, nowrap = false, wrapOnMobile = false, overflowOnMobile = true, reverseOnMac = false, children, } = props;
    const { isMobile } = useBreakpointDevice();
    if (!show)
        return null;
    return (_jsx(Box, { sx: Object.assign({ display: inline ? 'inline-flex' : 'flex', flexWrap: (() => {
                if (isMobile && wrapOnMobile)
                    return "wrap";
                return nowrap ? 'nowrap' : 'wrap';
            })(), flexDirection: isMac && reverseOnMac ? 'row-reverse' : undefined, justifyContent: isMac && reverseOnMac ? undefined : align, paddingTop: noVerticalSpace ? undefined : spacing, paddingLeft: noHorizontalSpace ? undefined : spacing, '& .MuiButton-root': {
                verticalAlign: 'bottom',
                marginBottom: noVerticalSpace ? undefined : spacing,
                marginRight: spacing,
                [`&:${isMac && reverseOnMac ? 'first' : 'last'}-child`]: { marginRight: noHorizontalSpace ? 0 : undefined },
            }, overflowX: isMobile && overflowOnMobile ? "auto" : undefined }, sx), dataComponentName: "ButtonBar", children: children }));
};
