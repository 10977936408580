import {ETrafficStudiesRights} from "mhc-server/dist/interfaces";

import {buildUrlByRoutePath} from "utils-library/dist/utils";

import {IAppRoute} from "../../application/config/IAppRoute";

import {TrafficStudiesListIcon} from "../assets/icons";

export const routeTrafficStudyListPaths: IAppRoute<{ searchObjectText: string }> = {
  menuId: "menu--trafficStudiesSearch",
  title: 'Manage Studies',
  subtitle: 'Create, edit and delete studies',
  description: "Create, Edit, and Delete Studies",
  icon: <TrafficStudiesListIcon/>,
  routePath: '/traffic-studies/list/:searchObjectText?',
  getRoutePath: ({searchObjectText} = {searchObjectText: ''}) =>
    buildUrlByRoutePath({
      routePath: routeTrafficStudyListPaths.routePath,
      pathParams: {searchObjectText},
    }),
  userHasAllRights: [],
  userHasAnyOfRights: [
    ETrafficStudiesRights.READ,
    ETrafficStudiesRights.WRITE,
  ],
  render: () => null,
};
