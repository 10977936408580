import * as React from "react";
import {connect} from "react-dynadux";

import {
  ButtonBar,
  EButtonBarAlign,
} from "mhc-ui-components/dist/ButtonBar";
import {
  Button,
  EButtonSize,
} from "mhc-ui-components/dist/Button";
import {IAppStore} from "../../../state";

import {routeAppMainPage} from "../routes/routeAppMainPage";

import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos";
import HomeIcon from "@mui/icons-material/Home";

export interface IGoHomeButtonProps {
  store: IAppStore;
}


type TWithoutStoreProp<TProps> = Omit<TProps, 'store'>;

type TConnectedComponent<TProps> =
  React.FunctionComponent<TWithoutStoreProp<TProps>> |
  React.ComponentClass<TWithoutStoreProp<TProps>>;


export const GoBackHomeButtons = connect((props: IGoHomeButtonProps): React.JSX.Element => {
  const {store: {app: {actions: {navigateTo}}}} = props;

  return (
    <ButtonBar align={EButtonBarAlign.CENTER}>
      <Button
        icon={<ArrowBackIosIcon/>}
        size={EButtonSize.LARGE}
        onClick={() => navigateTo({url: '<'})}
      >
        Go Back
      </Button>
      <Button
        icon={<HomeIcon/>}
        size={EButtonSize.LARGE}
        onClick={() => navigateTo({url: routeAppMainPage.getRoutePath()})}
      >
        Go Home
      </Button>
    </ButtonBar>
  );
}) as TConnectedComponent<IGoHomeButtonProps>;
