/**
 * Converts a Language ISO 639-1 code to its corresponding emoji flag.
 *
 * @example
 * getLanguageEmoji('en'); // Returns '🇬🇧'
 * getLanguageEmoji('fr'); // Returns '🇫🇷'
 * getLanguageEmoji('de'); // Returns '🇩🇪'
 *
 * @see {@link https://en.wikipedia.org/wiki/ISO_639-1}
 *
 * @note This function assumes a direct correspondence between language codes and country flags,
 * which may not always be accurate for all languages or regions.
 */
export const getEmojiFlagByLanguageIsoCode = (languageIsoCode) => {
    const countryCode = languageToCountryMap[languageIsoCode.toLowerCase()] || languageIsoCode;
    const codePoints = countryCode
        .slice(0, 2)
        .toUpperCase()
        .split('')
        .map(char => 127397 + char.charCodeAt(0));
    return String.fromCodePoint(...codePoints);
};
const languageToCountryMap = {
    en: 'GB', // English → UK (or use 'US' 🇺🇸)
    el: 'GR', // Greek → Greece
    de: 'DE', // German → Germany
    no: 'NO', // Norwegian → Norway
    fr: 'FR', // French → France
    es: 'ES', // Spanish → Spain
    zh: 'CN', // Chinese → China
    ja: 'JP', // Japanese → Japan
    ko: 'KR', // Korean → South Korea
    ru: 'RU', // Russian → Russia
    ar: 'SA', // Arabic → Saudi Arabia
    nl: 'NL', // Dutch → Netherlands
    sv: 'SE', // Swedish → Sweden
    da: 'DK', // Danish → Denmark
    fi: 'FI', // Finnish → Finland
    pt: 'PT', // Portuguese → Portugal (or use 'BR' 🇧🇷 for Brazil)
    hi: 'IN', // Hindi → India
    he: 'IL', // Hebrew → Israel
    tr: 'TR', // Turkish → Turkey
    uk: 'UA', // Ukrainian → Ukraine
};
