import {connect} from "react-dynadux";
import {dynaSwitch} from "dyna-switch";

import {
  ThemeProvider,
  EThemeName,
} from "mhc-ui-components/dist/ThemeProvider";

import {ThemeContainer} from "mhc-ui-components/dist/ThemeContainer";

import {IAppStore} from "../../../state";
import {AppRouterSwitch} from "./AppRouterSwitch";

import {appTopComponents} from "../config/appTopComponents";
import {CheckAppVersion} from "../components/CheckAppVersion";

import {EAppTheme} from "../state/appSection";

export interface IAppWithStyleProps {
  store: IAppStore;
}

export const AppWithStyle = connect((props: IAppWithStyleProps): JSX.Element => {
  const {
    theme: themeName,
    themeSize,
  } = props.store.app.state;

  const muiThemeName = dynaSwitch<EThemeName, EAppTheme>(
    themeName,
    EThemeName.MUI5_LIGHT,
    {
      [EAppTheme.LIGHT]: EThemeName.MUI5_LIGHT,
      [EAppTheme.DARK]: EThemeName.MUI5_DARK,
    },
  );

  return (
    <ThemeProvider
      themeName={muiThemeName}
      themeSize={themeSize}
    >
      <ThemeContainer fullHeight>
        {appTopComponents}
        <CheckAppVersion/>
        <AppRouterSwitch/>
      </ThemeContainer>
    </ThemeProvider>
  );
});
