export const getDefaultDBEntityDocument = (data, { partialBase = {} } = {}) => {
    return Object.assign(Object.assign(Object.assign({}, getDefaultDBEntityBase()), data), partialBase);
};
export const getDefaultDBEntityBase = () => ({
    id: '',
    createdAt: 0,
    createdBy: '',
    updatedAt: 0,
    updatedBy: '',
    archivedAt: 0,
    archivedBy: '',
    deletedAt: 0,
    deletedBy: '',
});
export const getDBEntityBasePropertiesOnly = (data) => Object.keys(getDefaultDBEntityBase())
    .reduce((acc, key) => {
    // @ts-ignore
    acc[key] = data[key];
    return acc;
}, getDefaultDBEntityBase());
const entityBase = getDefaultDBEntityDocument({});
export const dbEntityDataOnly = (data) => {
    const output = Object.assign({}, data);
    for (const key in entityBase)
        delete output[key];
    return output;
};
