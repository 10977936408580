import {ETrafficStudiesRights} from "mhc-server/dist/interfaces";
import {IAppRoute} from "../../application/config/IAppRoute";
import {TrafficStudiesFeatureIcon} from "../assets/icons";
import {TrafficStudiesMainPage} from "../pages/TrafficStudiesMainPage";

export const routeTrafficStudyMainPage: IAppRoute = {
  menuId: "menu--trafficStudies-mainPage",
  icon: <TrafficStudiesFeatureIcon/>,
  title: 'Traffic Studies',
  subtitle: 'Manage Traffic Studies',
  routePath: '/traffic-studies',
  getRoutePath: ()=>routeTrafficStudyMainPage.routePath,
  userHasAllRights: [],
  userHasAnyOfRights: Object.values(ETrafficStudiesRights),
  render: ()=> <TrafficStudiesMainPage/>,
};
