import {useEffect} from "react";
import {connect} from "react-dynadux";
import {dynaError} from "dyna-error";

import {EUserAuthenticationRights} from "mhc-server/dist/interfaces";
import {Typography} from "mhc-ui-components/dist/Typography";
import {LabelOverlay} from "mhc-ui-components/dist/LabelOverlay";

import {IAppStore} from "../../../state";
import {ESignStatus} from "../../user-authnentication/state/userAuthSection";

import {AppPageTitle} from "../components/AppPageTitle";
import {LoadingPage} from "../components/LoadingPage";

import {GoBackHomeButtons} from "../components/GoBackHomeButtons";

import {routeUserInvitationEntryPage} from "../../users-invitations/routes/routeUserInvitationEntryPage";

import {createIcon} from "mhc-ui-components/dist/IconComponent";
import AccessDeniedIcon from '@mui/icons-material/NotInterested';

export interface IAccessPageCheckProps {
  store: IAppStore;
  pageTitle: string;
  userHasAllRights: string[];
  userHasAnyOfRights: string[];
  signWithInvitation: boolean;
  children: JSX.Element;
}

export const AccessPageCheck = connect((props: IAccessPageCheckProps): JSX.Element => {
  const {
    store: {
      app: {state: {online}},
      userAuth: {
        state: {
          signStatus,
          user,
          user: {
            id: userId,
            rights: userRights,
          },
          userTokenExpiresAt,
        },
        actions: {
          signIn,
          signOut,
        },
        utils: {
          userHasAllRights,
          userHasAnyOfRights,
        },
      },
    },
    pageTitle,
    userHasAllRights: pageUserHasAllRights,
    userHasAnyOfRights: pageUserHasAnyOfRights,
    children,
  } = props;

  const isFreePage = !pageUserHasAllRights.length && !pageUserHasAnyOfRights.length;
  const userIsSignedIn = signStatus === ESignStatus.SIGNED_IN || signStatus === ESignStatus.SIGNING_OUT;
  const userHasRights = !!userRights.length;
  const userIsJustActive = userRights.join('') === EUserAuthenticationRights.ACTIVE;
  const currentPath = window.location.pathname + window.location.search;

  // Validate the Signed In user and redirect him if needed
  useEffect(() => {
    if (isFreePage) {
      // The page doesn't require from the user to have any rights, so show the content.
      return;
    }

    if (
      signStatus === ESignStatus.SIGNED_OUT
      && !userIsSignedIn
    ) {
      signIn(currentPath, window.location.pathname === routeUserInvitationEntryPage.routePath);
      return;
    }

    if (
      signStatus === ESignStatus.SIGNED_IN
      && (
        !userHasRights
        || userIsJustActive
      )
    ) {
      signOut(dynaError({
        code: 202105241354,
        message: 'User has no access on this app, he/she is only Active',
        userMessage: [
          "Your login was successful however you do not have any access to this application.",
          "Ask for an invitation.",
          "If you already have an invitation, click the \"I have invitation\" button.",
        ].join('\n'),
        data: {user: {...user}},
      }));
      return;
    }
  }, [
    currentPath,
    userIsSignedIn,
    userHasRights,
    userIsJustActive,
  ]);

  // If the page doesn't require user right, return the given content.
  if (isFreePage) {
    return (
      <>
        <AppPageTitle>{pageTitle}</AppPageTitle>
        {children}
      </>
    );
  }

  switch (signStatus) {
    case ESignStatus.SIGNED_IN: {
      // If token is expired, show a message
      if (
        !!userId
        && userTokenExpiresAt < Date.now()
      ) {
        return online
          ? (
            <div>
              <AppPageTitle>Token expired</AppPageTitle>
              <LabelOverlay
                Icon={createIcon.byMuiIcon(AccessDeniedIcon)}
                title="Token expired"
                description="Please refresh the page"
              >
                <Typography>You are seeing this because the application has not been online for a long time.</Typography>
                <Typography>For security reasons your access has expired.</Typography>
                <Typography>By refreshing the page you will either be able to continue as expected or you will be required to log back in.</Typography>
                <GoBackHomeButtons/>
              </LabelOverlay>
            </div>
          )
          : (
            <div>
              <AppPageTitle>Offline use expired</AppPageTitle>
              <LabelOverlay
                Icon={createIcon.byMuiIcon(AccessDeniedIcon)}
                title="Offline use expired"
                description="You have been offline for too long and you are now logged out."
              >
                <Typography>This happened due to security requirements.</Typography>
                <Typography>To use the app offline for an extended period you will need to refresh your credentials by allowing the application to be online.</Typography>
              </LabelOverlay>
            </div>
          );
      }

      // The user is Signed In but we have to check if he has the right to access this page
      const userHasAccess =
        userHasAllRights(pageUserHasAllRights) &&
        userHasAnyOfRights(pageUserHasAnyOfRights);
      if (!userHasAccess) {
        return (
          <div>
            <AppPageTitle>{'Access denied - ' + pageTitle}</AppPageTitle>
            <LabelOverlay
              Icon={createIcon.byMuiIcon(AccessDeniedIcon)}
              title="Error: 403"
              description="Access denied"
            >
              <Typography bold>You don't have the user rights to access this page.</Typography>
              <Typography>Contact your Administrator to gain access.</Typography>
              <GoBackHomeButtons/>
            </LabelOverlay>
          </div>
        );
      }

      // Finally, return the content
      return (
        <>
          <AppPageTitle>{pageTitle}</AppPageTitle>
          {children}
        </>
      );
    }
    case ESignStatus.SIGNED_OUT:
      return <LoadingPage primaryText="You are signed out"/>;

    case ESignStatus.SIGNING_IN:
      return <LoadingPage primaryText="Signing in..."/>;

    case ESignStatus.SIGNING_OUT:
      return <LoadingPage primaryText="Signing out..."/>;

    case ESignStatus.SIGNING_UP:
      return <LoadingPage primaryText="Signing up..."/>;

    default:
      // 4TS, this case is not possible
      console.error(`internal error: ESignStatus [${signStatus}] is not implemented`);
      return <LoadingPage primaryText={signStatus}/>;
  }
});
