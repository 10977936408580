import {INotificationIcon} from "mhc-ui-components/dist/AppContainerDashboard";

import {IAppStore} from "../../../../state";

import OnlineWithPendingOfflineItemsIcon from "@mui/icons-material/Cloud";
import OnlineCompletedIcon from "@mui/icons-material/CloudDone";
import OfflineIcon from "@mui/icons-material/CloudOff";
import UploadingOfflineIcons from "@mui/icons-material/CloudUpload";

export const onlineNotificationIcon = (store: IAppStore): INotificationIcon | null => {
  const {
    app: {state: {online}},
    workzonesManagement: {
      state: {
        uploadingOfflineItems,
        offlineUnsavedItems,
      },
      actions: {uploadOfflineChanges},
    },
  } = store;

  return {
    icon: ((): JSX.Element => {
      if (!online) return <OfflineIcon/>;
      if (uploadingOfflineItems) return <UploadingOfflineIcons/>;
      if (offlineUnsavedItems) <OnlineWithPendingOfflineItemsIcon/>;
      return (
        <OnlineCompletedIcon
          onClick={uploadOfflineChanges}
        />
      );
    })(),
    label: ((): string => {
      if (!online) return 'Offline';
      if (uploadingOfflineItems) return 'Uploading';
      if (offlineUnsavedItems) return 'Online, with offline items';
      return 'Online, no pending offline items';
    })(),
    counter: offlineUnsavedItems,
  };
};
