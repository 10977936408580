export const arrayDuplicates = (array) => {
    const seen = new Set();
    const duplicates = new Set();
    for (let i = 0; i < array.length; i++) {
        if (seen.has(array[i])) {
            duplicates.add(array[i]);
        }
        else {
            seen.add(array[i]);
        }
    }
    return Array.from(duplicates);
};
