import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { dynaSwitchEnum } from "dyna-switch";
import MuiIconButton from '@mui/material/IconButton';
import { Box } from "../Box";
import { Ghost } from "../Ghost";
import { Link, ELinkColor, ELinkUnderline, } from "../Link";
import { useTheme, } from "../ThemeProvider";
export var EIconButtonSize;
(function (EIconButtonSize) {
    EIconButtonSize["XXXSMALL"] = "XXXSMALL";
    EIconButtonSize["XXSMALL"] = "XXSMALL";
    EIconButtonSize["XSMALL"] = "XSMALL";
    EIconButtonSize["SMALL"] = "SMALL";
    EIconButtonSize["MEDIUM"] = "MEDIUM";
    EIconButtonSize["LARGE"] = "LARGE";
    EIconButtonSize["XLARGE"] = "XLARGE";
    EIconButtonSize["XXLARGE"] = "XXLARGE";
})(EIconButtonSize || (EIconButtonSize = {}));
export var ELabelPosition;
(function (ELabelPosition) {
    ELabelPosition["LEFT"] = "LEFT";
    ELabelPosition["RIGHT"] = "RIGHT";
})(ELabelPosition || (ELabelPosition = {}));
export const IconButton = (props) => {
    const { ariaLabel, ariaHasPopUp = false, ariaControls, sx = {}, show = true, ghost = false, noPadding, icon, Icon, label, labelPosition = ELabelPosition.RIGHT, tooltip, size = EIconButtonSize.MEDIUM, disabled, disableRipple, href, onClick, } = props;
    const theme = useTheme();
    if (!show)
        return null;
    if (!icon && !Icon)
        return null;
    const iconSize = parseFloat(dynaSwitchEnum(size, {
        [EIconButtonSize.XXXSMALL]: theme.spacing(1),
        [EIconButtonSize.XXSMALL]: theme.spacing(1.25),
        [EIconButtonSize.XSMALL]: theme.spacing(1.5),
        [EIconButtonSize.SMALL]: theme.spacing(1.75),
        [EIconButtonSize.MEDIUM]: theme.spacing(1.9),
        [EIconButtonSize.LARGE]: theme.spacing(2.5),
        [EIconButtonSize.XLARGE]: theme.spacing(3),
        [EIconButtonSize.XXLARGE]: theme.spacing(4),
    })) * 2;
    return (_jsx(Link, { href: href, color: ELinkColor.INHERIT, underline: ELinkUnderline.NONE, children: _jsxs(MuiIconButton, { "aria-label": ariaLabel, "aria-haspopup": ariaHasPopUp, "aria-controls": ariaControls, sx: Object.assign(Object.assign({}, sx), { borderRadius: iconSize + "px", overflow: "hidden" }), style: { padding: noPadding ? 0 : undefined }, title: tooltip, disabled: disabled, disableRipple: disableRipple, onClick: onClick, children: [_jsx(Ghost, { show: ghost }), _jsx(Box, { show: !!label && labelPosition === ELabelPosition.LEFT, sx: { marginRight: 1 }, children: label }), icon, Icon && (_jsx("span", { style: {
                        borderRadius: '50%',
                        overflow: 'hidden',
                        fontSize: 0,
                    }, children: _jsx(Icon, { width: iconSize }) })), _jsx(Box, { show: !!label && labelPosition === ELabelPosition.RIGHT, sx: {
                        marginLeft: 1,
                        fontSize: iconSize,
                    }, children: label })] }) }));
};
