import { EType, flatObject, unflatObject, } from "./utils/flatObject";
/**
 * Serialize/deserialize a data object to URL query, respecting url query standards and maintain the original JS type of the values.
 *
 * It works like the know `qs` but it maintains the data type even better than native JSON.
 * This is because it keeps in the serialized version the type of each value in very compact form.
 */
export const urlQueryBuilder = {
    serialize: (data) => {
        const flat = flatObject(data);
        const parts = [];
        const types = [];
        for (const flatItem of flat) {
            parts.push(flatItem.path + '=' + encodeURIComponent(flatItem.value));
            types.push(flatItem.type);
        }
        return parts.length
            ? parts.join('&') + '&' + typesQueryName + '=' + types.join('')
            : "";
    },
    deserialize: (serialized) => {
        if (!serialized)
            return {};
        const parts = serialized.split('&');
        // Resolve the types of each flattened property if it's serialized by the url-query-builder.
        // Otherwise, everything will be returned as a string, as per the standard.
        const types = (() => {
            if (!serialized.includes('&' + typesQueryName + '='))
                return [];
            const typesPart = parts.pop();
            if (!typesPart)
                return []; // 4TS
            return typesPart.split('=')[1].split('');
        })();
        const flatItems = parts
            .map((part, index) => ({
            path: part.split('=')[0],
            value: decodeURIComponent(part.split('=')[1]),
            type: (() => {
                const type = types[index] || EType.STRING;
                return type;
            })(),
        }));
        return unflatObject(flatItems);
    },
};
const typesQueryName = "__uqbsx";
