import { dynaError, } from "dyna-error";
import { encrypt, decrypt, } from "dyna-crypt";
import { JSONParse } from "../utils/JSONParse";
/**
 * XenCrypt uses AES-128 to encrypt and decrypt text or objects, and the ciphertext can be easily copy-pasted and shared in URLs without any extra formatting.
 *
 * The ciphertext has a minimum length of 60 characters.
 */
export class XenCrypt {
    constructor(config) {
        this.config = config;
    }
    encryptText(plainText) {
        if (!plainText)
            return "";
        return encodeText(encrypt(plainText, this.config.encryptionKey));
    }
    decryptText(cipherText) {
        return decrypt(decodeText(cipherText), this.config.encryptionKey) || null;
    }
    decryptTextOrThrowError(cipherText, errorCode) {
        const output = this.decryptText(cipherText);
        if (!output) {
            throw dynaError({
                code: errorCode,
                status: 401,
                message: 'xen decryption failed',
                userMessage: 'There is need to refresh the page',
            });
        }
        return output;
    }
    encryptData(plainObject) {
        return encodeText(encrypt(JSON.stringify(plainObject), this.config.encryptionKey));
    }
    decryptData(cipherObject) {
        const decrypted = decrypt(decodeText(cipherObject), this.config.encryptionKey);
        if (!decrypted) {
            return {
                error: dynaError({
                    code: 52255649969190,
                    message: "Decryption failed or the provided cipher was broken",
                    data: { cipherObject },
                }),
            };
        }
        const { data, error, } = JSONParse({
            serialized: decrypted,
            default: null,
        });
        if (error) {
            return {
                error: dynaError({
                    code: 52255649969190,
                    message: "JSON parse error: Data decrypted bad cannot be parsed",
                    data: {
                        cipherObject,
                        decryptedData: data,
                    },
                }),
            };
        }
        return { data };
    }
}
export const isXenCipher = (value) => {
    if (typeof value !== "string")
        return "Xen cipher should be a string";
    if (value.length < 50)
        return "Xen cipher should bigger text";
    if (!value.startsWith(prefixer))
        return "Is not a Xen cipher";
    return "";
};
const prefixer = 'xen';
const splitter = 'hx73c';
const encodeText = (sourceText) => prefixer
    + encodeURIComponent(sourceText)
        .replace(/%/g, splitter);
const decodeText = (encodedText) => decodeURIComponent(encodedText
    .slice(prefixer.length)
    .replace(new RegExp(splitter, 'g'), '%'));
