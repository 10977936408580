import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { dynaSwitch } from "dyna-switch";
import List from "@mui/material/List";
import Divider from "@mui/material/Divider";
import ListItem from "@mui/material/ListItem";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import { EOpenMode, } from "../../interfaces";
import { FlexContainerHorizontal, FlexContainerVertical, FlexItemMin, FlexItemMax, } from "../../../FlexContainer";
import { CenterContainer } from "../../../CenterContainer";
import { Box } from "../../../Box";
import { IconViewer } from "../../../IconViewer";
import { BreakpointDeviceContainer } from "../../../BreakpointDeviceContainer";
import { Button, EButtonVariant, EButtonColor, EButtonTextAlign, } from "../../../Button";
import { IconButton } from "../../../IconButton";
import { useTheme } from "../../../ThemeProvider";
import { useBreakpointDevice } from "../../../useBreakpointDevice";
import { AppLogo } from "../AppLogo/AppLogo";
import { sxTransitionShowHide } from "../../../sxTransition";
import FiberManualRecordIcon from "@mui/icons-material/FiberManualRecord";
import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import CloseIcon from '@mui/icons-material/Close';
const DEFAULT_EXPANDED_WIDTH = 256 + 42;
const MINIMIZED_WIDTH = 64;
export const AsideMenu = (props) => {
    const theme = useTheme();
    const { logoSquareImage, logoLandscapeImage, openMode, menuItemsTop = [], menuItems, menuItemsBottom = [], menuFullScreenOnMobile, menuFullScreenOnTablet, showMinimizedOption, bottomAppInfo, onLogoClick, onToggleClick, onDrawerClose, onClick, } = props;
    const isOpen = openMode !== EOpenMode.COLLAPSED;
    const { isMobile, isTablet, } = useBreakpointDevice();
    const EXPANDED_WIDTH = isMobile || isTablet
        ? window.innerWidth
        : DEFAULT_EXPANDED_WIDTH;
    const renderMenuItems = (menuItems) => {
        if (!menuItems.length)
            return null;
        const output = [];
        let index = 0;
        let level = 0;
        const processMenuItem = (menuItem) => {
            output.push(renderMenuItem(menuItem, index++, level));
            if (menuItem !== 'DIVIDER' && menuItem.children) {
                level++;
                menuItem.children.forEach(processMenuItem);
                level--;
            }
        };
        menuItems
            .forEach(processMenuItem);
        return (_jsx(List, { children: output }));
    };
    const renderMenuItem = (menuItem, index, level) => {
        if (menuItem === 'DIVIDER') {
            const leftRightMargin = dynaSwitch(openMode, 0, {
                [EOpenMode.EXPANDED]: level * 2,
                [EOpenMode.MINIMIZED]: 0,
                [EOpenMode.COLLAPSED]: 0,
            });
            return (_jsx(Divider, { sx: {
                    marginLeft: leftRightMargin,
                    marginRight: leftRightMargin,
                    transition: sxTransitionShowHide(theme, ['margin-left', 'margin-right'], isOpen),
                } }, index));
        }
        const { selectedMenuId } = props;
        const selected = !!menuItem.menuId && !!selectedMenuId && menuItem.menuId === selectedMenuId;
        if (menuItem.hidden)
            return null;
        const showDescription = !!menuItem.description
            && openMode === EOpenMode.EXPANDED;
        return (_jsxs(ListItem, { button: true, disabled: menuItem.disabled, selected: selected, sx: {
                overflow: 'hidden',
                paddingLeft: dynaSwitch(openMode, 0, {
                    [EOpenMode.EXPANDED]: 2 + (level * 2),
                    [EOpenMode.MINIMIZED]: 2,
                    [EOpenMode.COLLAPSED]: 2,
                }),
                transition: sxTransitionShowHide(theme, 'padding-left', isOpen),
            }, onClick: () => menuItem.onClick && menuItem.onClick(), children: [_jsx(ListItemIcon, { title: menuItem.title, sx: {
                        'svg': {
                            width: theme => theme.typography.fontSize * 2,
                            height: theme => theme.typography.fontSize * 2,
                        },
                    }, children: menuItem.Icon
                        ? _jsx(IconViewer, { Icon: menuItem.Icon })
                        : menuItem.icon || _jsx(FiberManualRecordIcon, {}) }), _jsx(ListItemText, { sx: { whiteSpace: 'break-spaces' }, primary: menuItem.title, secondary: showDescription ? menuItem.description : undefined })] }, index));
    };
    return (_jsxs(FlexContainerVertical, { dataComponentName: "AsideMenu", fullHeight: true, sx: {
            backgroundColor: theme.palette.background.paper,
            width: dynaSwitch(openMode, 0, {
                [EOpenMode.EXPANDED]: EXPANDED_WIDTH,
                [EOpenMode.MINIMIZED]: MINIMIZED_WIDTH,
                [EOpenMode.COLLAPSED]: 0,
            }),
            overflow: 'hidden',
            transition: sxTransitionShowHide(theme, 'width', isOpen),
        }, onClick: onClick, children: [_jsx(FlexItemMin, { children: _jsxs(FlexContainerHorizontal, { children: [_jsx(FlexItemMax, { children: _jsx(Button, { padding: 0, noFontSize: true, textAlign: EButtonTextAlign.LEFT, variant: EButtonVariant.TRANSPARENT, onClick: onLogoClick, children: _jsx(AppLogo, { squareLogoImage: logoSquareImage, landscapeLogoImage: logoLandscapeImage }) }) }), _jsx(FlexItemMin, { fullHeight: true, children: _jsx(CenterContainer, { children: _jsx(IconButton, { ariaLabel: "Close menu", icon: theme.direction === 'rtl' ? _jsx(ChevronRightIcon, {}) : _jsx(ChevronLeftIcon, {}), onClick: onDrawerClose }) }) })] }) }), _jsx(FlexItemMin, { children: renderMenuItems(menuItemsTop) }), _jsx(FlexItemMax, { overFlowY: true, children: renderMenuItems(menuItems) }), _jsx(FlexItemMin, { children: renderMenuItems(menuItemsBottom) }), _jsx(FlexItemMin, { show: showMinimizedOption, children: _jsx(BreakpointDeviceContainer, { allExcept: true, mobile: menuFullScreenOnMobile, tablet: menuFullScreenOnTablet, children: renderMenuItems([{
                            icon: dynaSwitch(openMode, null, {
                                [EOpenMode.EXPANDED]: _jsx(ChevronLeftIcon, {}),
                                [EOpenMode.MINIMIZED]: _jsx(ChevronRightIcon, {}),
                                [EOpenMode.COLLAPSED]: _jsx(ChevronLeftIcon, {}),
                            }),
                            title: dynaSwitch(openMode, '', {
                                [EOpenMode.EXPANDED]: 'Minimize',
                                [EOpenMode.MINIMIZED]: 'Expand',
                                [EOpenMode.COLLAPSED]: 'Minimize',
                            }),
                            onClick: onToggleClick,
                        }]) }) }), _jsx(FlexItemMin, { children: _jsx(BreakpointDeviceContainer, { mobile: menuFullScreenOnMobile, tablet: menuFullScreenOnTablet, children: _jsx(Button, { variant: EButtonVariant.OUTLINED, icon: _jsx(CloseIcon, {}), color: EButtonColor.ERROR, sx: {
                            marginLeft: theme.spacing(1),
                            marginRight: theme.spacing(1),
                            width: `calc(100% - ${theme.spacing(2)})`,
                        }, children: "Close" }) }) }), _jsx(FlexItemMin, { show: !!bottomAppInfo, sx: {
                    width: EXPANDED_WIDTH,
                    opacity: openMode === EOpenMode.EXPANDED ? 1 : 0,
                    transition: sxTransitionShowHide(theme, "opacity", isOpen),
                }, children: _jsx(Box, { sx: { margin: 1 }, children: bottomAppInfo }) })] }));
};
