import "abortcontroller-polyfill/dist/polyfill-patch-fetch";

import ResizeObserver from "resize-observer-polyfill";
if (!window.ResizeObserver) window.ResizeObserver = ResizeObserver;

// Core-js help

import "core-js/actual/promise";

import "core-js/actual/array/fill";
import "core-js/actual/array/flat";
import "core-js/actual/array/includes";
import "core-js/actual/array/entries";

import "core-js/actual/string/includes";
import "core-js/actual/string/starts-with";
import "core-js/actual/string/ends-with";

import "core-js/actual/object/entries";
import "core-js/actual/object/from-entries";

import "./polyfills/intersection-observer";
