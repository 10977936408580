import {EUserAuthenticationRights} from "mhc-server/dist/interfaces";

import {IAppRoute} from "../../application/config/IAppRoute";
import {UserManagementSectionIcon} from "../assets/icons";

export const routeUserManagementMainPagePaths: IAppRoute = {
  menuId: '##menu--userManagement-main-page',
  icon: <UserManagementSectionIcon/>,
  title: 'User management',
  subtitle: 'Manage the users of the application',
  description: "Invite and manage users, roles and permissions",
  routePath: '/users-management',
  getRoutePath: () => routeUserManagementMainPagePaths.routePath,
  userHasAllRights: [],
  userHasAnyOfRights: Object.values(EUserAuthenticationRights),
  render: () => null,
};
