import {IAppRoute} from "../../application/config/IAppRoute";
import {EDeviceManagementRights} from "mhc-server/dist/interfaces";
import {SettingsIcon} from "mhc-ui-components/dist/graphics-common-icons";

export const routeDeviceMgtmSettingsPaths: IAppRoute = {
  menuId: '##devive-mgtm-settings',
  icon: <SettingsIcon/>,
  title: "Settings",
  subtitle: 'Device management settings',
  description: 'Set up the device management feature',
  routePath: '/device-management/settings',
  getRoutePath: () => routeDeviceMgtmSettingsPaths.routePath,
  userHasAnyOfRights: [EDeviceManagementRights.ADMINISTRATOR],
  userHasAllRights: [],
  render: () => null,
};
