import {ELanePanningRights} from "mhc-server/dist/interfaces";
import {IAppRoute} from "../../application/config/IAppRoute";
import {LanePlanningHome} from "../pages/LanePlanningMainPage";
import {LanePlanningSectionIcon} from "../assets/icons";


export const routeLanePlanningMainPage: IAppRoute = {
  menuId: '##line-planning-home',
  icon: <LanePlanningSectionIcon/>,
  title: 'Lane Planning',
  subtitle: 'Manage and export lane plans',
  description: 'Create lane plans on the map and export them',
  routePath: '/lane-planning',
  getRoutePath: () => routeLanePlanningMainPage.routePath,
  userHasAnyOfRights: Object.values(ELanePanningRights),
  userHasAllRights: [],
  render: () => <LanePlanningHome/>,
};
