import {createIcon} from "mhc-ui-components/dist/IconComponent";
import SourceTrafficStudiesSectionIcon from '@iconify-icons/carbon/vehicle-insights';
import SourceTrafficStudiesListIcon from '@mui/icons-material/ElectricCar';
import {SettingsIcon as SourceTrafficStudyConfiguration} from "mhc-ui-components/dist/graphics-common-icons";
import SourcePublishIcon from '@mui/icons-material/Send';
import SourceOverviewIcon from '@mui/icons-material/ViewCompact';
import SourceCopyToNew from '@mui/icons-material/FileCopy';
import SourceCollectedDataIcon from '@mui/icons-material/Storage';
import SourceReportsIcon from '@mui/icons-material/AutoGraph';
import SourceLogsIcon from '@mui/icons-material/FormatListBulleted';

export const TrafficStudiesFeatureIcon = createIcon.byIconifyIcon(SourceTrafficStudiesSectionIcon);
export const TrafficStudiesListIcon = createIcon.byMuiIcon(SourceTrafficStudiesListIcon);
export const TrafficStudyConfiguration = createIcon.byMuiIcon(SourceTrafficStudyConfiguration);
export const PublishIcon = createIcon.byMuiIcon(SourcePublishIcon);
export const OverviewIcon = createIcon.byMuiIcon(SourceOverviewIcon);
export const CopyToNew = createIcon.byMuiIcon(SourceCopyToNew);
export const TrafficDataIcon = createIcon.byMuiIcon(SourceCollectedDataIcon);
export const ReportsIcon = createIcon.byMuiIcon(SourceReportsIcon);
export const LogsIcon = createIcon.byMuiIcon(SourceLogsIcon);
