import { jsx as _jsx } from "react/jsx-runtime";
import MuiCollapse from "@mui/material/Collapse";
export const Collapse = (props) => {
    const { sx, expanded, show = true, animation = true, animationDuration = 150, doNotRemoveFromDom = false, orientation = 'vertical', children, } = props;
    if (!show)
        return null;
    if (!animation)
        return expanded ? children : null;
    return (_jsx(MuiCollapse, { sx: sx, in: expanded, timeout: animationDuration, orientation: orientation, mountOnEnter: !doNotRemoveFromDom, unmountOnExit: !doNotRemoveFromDom, children: children }));
};
