import {EUserAuthenticationRights} from "mhc-server/dist/interfaces";

import {AppIcon} from "mhc-ui-components/dist/graphics-common-icons";
import {IAppRoute} from "../config/IAppRoute";
import {AppAboutPage} from "../pages/AppAboutPage";


export const routeAppAboutPage: IAppRoute = {
  menuId: "app-about",
  title: 'About',
  icon: <AppIcon/>,
  routePath: '/about',
  getRoutePath: () => routeAppAboutPage.routePath,
  userHasAllRights: [],
  userHasAnyOfRights: [EUserAuthenticationRights.ACTIVE],
  render: () => <AppAboutPage/>,
};
