import { dynaError } from "dyna-error";
export var EType;
(function (EType) {
    EType["STRING"] = "s";
    EType["NUMBER"] = "n";
    EType["DATE"] = "d";
    EType["BOOLEAN"] = "b";
    EType["NULL"] = "u";
    EType["UNDEFINED"] = "x";
    EType["EMPTY_OBJECT"] = "e";
    EType["EMPTY_ARRAY"] = "a";
})(EType || (EType = {}));
export const flatObject = (obj, path = '') => {
    const result = [];
    for (const key in obj) {
        if (Object.prototype.hasOwnProperty.call(obj, key)) {
            const newPath = path ? `${path}.${key}` : key;
            const value = obj[key];
            const flatArrayValue = (values, path) => {
                if (values.length === 0) {
                    result.push({
                        path: path,
                        value: '',
                        type: EType.EMPTY_ARRAY,
                    });
                }
                else {
                    for (let i = 0; i < values.length; i++) {
                        const value = values[i];
                        if (Array.isArray(value)) {
                            // Recursively handle array
                            flatArrayValue(value, `${path}[${i}]`);
                        }
                        else if (isObject(value)) {
                            // Recursively handle objects within the array
                            result.push(...flatObject(value, `${path}[${i}]`));
                        }
                        else {
                            // Handle primitive values or dates in array
                            result.push(getTypeStringValue(`${path}[${i}]`, value));
                        }
                    }
                }
            };
            const flatObjectValue = (value) => {
                if (Object.keys(value).length === 0) {
                    result.push({
                        path: newPath,
                        value: '',
                        type: EType.EMPTY_OBJECT,
                    });
                }
                else {
                    result.push(...flatObject(value, newPath));
                }
            };
            if (Array.isArray(value)) {
                flatArrayValue(value, newPath);
            }
            else if (isObject(value)) {
                flatObjectValue(value);
            }
            else {
                result.push(getTypeStringValue(newPath, value));
            }
        }
    }
    return result;
};
const getTypeStringValue = (path, value) => {
    switch (typeof value) {
        case 'string':
            return {
                path,
                type: EType.STRING,
                value,
            };
        case 'number':
            return {
                path,
                type: EType.NUMBER,
                value: String(value),
            };
        case 'boolean':
            return {
                path,
                type: EType.BOOLEAN,
                value: String(value),
            };
        case 'object':
            if (value instanceof Date) {
                return {
                    path,
                    type: EType.DATE,
                    value: value.toISOString(),
                };
            }
            else {
                return {
                    path,
                    type: EType.NULL,
                    value: 'null',
                };
            }
        case 'undefined':
            return {
                path,
                type: EType.UNDEFINED,
                value: 'undefined',
            };
        default:
            throw dynaError({
                code: 52274865957999,
                message: `Unsupported type: ${typeof value}`,
                data: {
                    type: typeof value,
                    value,
                },
            });
    }
};
export const unflatObject = (flatItems) => {
    const result = {};
    for (const item of flatItems) {
        const pathParts = item.path.replace(/\[/g, '.').replace(/\]/g, '')
            .split('.');
        let currentObj = result;
        for (let i = 0; i < pathParts.length; i++) {
            const part = pathParts[i];
            if (i === pathParts.length - 1) {
                // Set value for property
                currentObj[part] = parseValue(item.value, item.type);
            }
            else {
                if (!currentObj[part]) {
                    // Determine if next part is an array index
                    const nextPart = pathParts[i + 1];
                    const isNextPartIndex = !isNaN(Number(nextPart));
                    currentObj[part] = isNextPartIndex ? [] : {};
                }
                currentObj = currentObj[part];
            }
        }
    }
    return result;
};
const parseValue = (value, type) => {
    switch (type) {
        case EType.STRING:
            return value;
        case EType.NUMBER:
            return Number(value);
        case EType.DATE:
            return new Date(value);
        case EType.BOOLEAN:
            return value === 'true';
        case EType.NULL:
            return null;
        case EType.EMPTY_OBJECT:
            return {};
        case EType.EMPTY_ARRAY:
            return [];
        case EType.UNDEFINED:
            return undefined;
        default:
            throw new Error(`Unsupported type: ${type}`);
    }
};
const isObject = (value) => typeof value === 'object' && value !== null && !(value instanceof Date);
