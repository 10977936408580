import {connect} from "react-dynadux";
import {
  lazy,
  LazyLoadComponent,
} from "mhc-ui-components/dist/LazyLoadComponent";
import {IDeviceManagementTreeV2Props} from "./DeviceManagementTreeV2";

const DeviceManagementVirtualTreeLazy = lazy(() => import("./DeviceManagementTreeV2"));

export const DeviceManagementVirtualTree = connect((props: IDeviceManagementTreeV2Props) => (
  <LazyLoadComponent>
    <DeviceManagementVirtualTreeLazy {...props}/>
  </LazyLoadComponent>
));
