import {buildUrlByRoutePath} from "utils-library/dist/utils";

import {
  EWorkZonesManagementRights,
} from "mhc-server/dist/interfaces";

import {IAppRoute} from "../../application/config/IAppRoute";

import {WorkzonesListPage} from "../pages/WorkzonesListPage";

import {CIMsListIcon} from "../assets/icons";

export const routeWorkZonesList: IAppRoute<{ search?: string }> = {
  title: 'CIMs Management',
  subtitle: 'Manage the CIM items',
  description: 'Create and edit CIMs for use',
  icon: <CIMsListIcon/>,
  routePath: '/cims/list/:search?/',
  getRoutePath: (args) => buildUrlByRoutePath({
    routePath: routeWorkZonesList.routePath,
    pathParams: {search: args?.search},
  }),
  menuId: 'cims-list',
  userHasAllRights: [],
  userHasAnyOfRights: [
    EWorkZonesManagementRights.WORKZONES_VIEW,
    EWorkZonesManagementRights.WORKZONES_EDIT,
  ],
  render: ({pathParams: {search = ""}}) =>
    <WorkzonesListPage
      search={search}
    />,
};
