import { dynaError } from "dyna-error";
import { arrayDuplicates } from "../array";
/**
 * Converts a number to a custom base using a given set of unique characters.
 *
 *
 */
export const convertIntegerToCustomBase = (base) => {
    if (typeof base !== "string") {
        throw dynaError({
            message: 'convertToCustomBase: Invalid base string, is not a string',
            data: { base },
        });
    }
    if (!base) {
        throw dynaError({
            message: 'convertToCustomBase: Invalid base string, it is empty or not defined',
            data: { base },
        });
    }
    const duplicateChars = arrayDuplicates(base.split(''));
    if (duplicateChars.length) {
        throw dynaError({
            message: `convertToCustomBase: Some given base chars are duplicated [${duplicateChars.join(',')}]`,
            data: {
                base,
                duplicateChars,
            },
        });
    }
    return { convert: (integer) => convertIntegerToCustomBaseCore(integer, base) };
};
const convertIntegerToCustomBaseCore = (n, baseString) => {
    if (!Number.isInteger(n)) {
        throw dynaError({
            message: 'convertToCustomBase: given number is not integer, no decimal points allowed',
            data: {
                value: n,
                base: baseString,
            },
        });
    }
    if (n === 0)
        return baseString[0];
    const base = baseString.length;
    let result = "";
    let num = n;
    while (num > 0) {
        result = baseString[num % base] + result;
        num = Math.floor(num / base);
    }
    return result;
};
