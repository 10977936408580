import { dynaError, } from "dyna-error";
export const JSONParse = ({ serialized, default: defaultValue, errorCode = 20240904091614, errorMessage, onError, }) => {
    if (!serialized)
        return { data: defaultValue };
    try {
        return { data: JSON.parse(serialized) };
    }
    catch (e) {
        const parseError = dynaError(e);
        const error = dynaError({
            code: errorCode,
            message: errorMessage || `JSONParse: Parsing error: ${parseError.message}, default is applied`,
            data: {
                serialized,
                default: defaultValue,
            },
            parentError: parseError,
        });
        onError === null || onError === void 0 ? void 0 : onError(error);
        return {
            data: defaultValue,
            error,
        };
    }
};
