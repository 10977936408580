import {connect} from "react-dynadux";
import {dynaError} from "dyna-error";
import {DynaLocalStorageData} from "dyna-local-storage";

import {DisclaimerModal} from "mhc-ui-components/dist/DisclaimerModal";
import {Link} from "mhc-ui-components/dist/Link";

import {IAppStore} from "../../../state";
import {ESignStatus} from "../../user-authnentication/state/userAuthSection";

export interface IDisclaimerOhioProps {
  store: IAppStore;
}

const ls = new DynaLocalStorageData<{ disclaimerShownForLocalSessionId: string }>(
  'DisclaimerOhio',
  {disclaimerShownForLocalSessionId: ''},
);

export const DisclaimerOhio = connect((props: IDisclaimerOhioProps): JSX.Element | null => {
  const {
    userAuth: {
      state: {
        user: {idHash: userIdHash},
        signStatus,
        localSessionId,
      },
    },
  } = props.store;

  if (signStatus !== ESignStatus.SIGNED_IN) return null;                        // Exit, is not signed in
  if (!userIdHash) return null;                                                     // Exit, is not active user
  if (localSessionId === ls.data.disclaimerShownForLocalSessionId) return null; // Exit, is already shown.

  const handleAgree = () => {
    ls.data.disclaimerShownForLocalSessionId = localSessionId;
    ls.save();
  };

  const handleDisagree = () => {
    props.store.userAuth.actions.signOut(dynaError({
      message: 'Disclaimer disagree, sign out',
      userMessage: 'You have been logged out of the system because you have declined to consent to the Security Agreement.',
    }));
  };

  return (
    <DisclaimerModal
      title="Security Agreement"
      content={(
        <>
          <b>This system contains State of Ohio and United States government information and is restricted to authorized users ONLY.</b>
          <br/>
          <br/>
          Unauthorized access, use, misuse, or modification of this computer system or of the data contained herein or in transit to and from this system is strictly prohibited, may be in violation of state and federal law, and may be
          subject to administrative action, civil and criminal penalties.
          <br/>
          Use of the system is governed by U.S. law and Ohio law and policies.
          <br/>
          You have no expectation of privacy in any material placed or viewed on this system except where Ohio or Federal statutes expressly provide for such status. The State of Ohio monitors activities on this system and may record and
          disclose those activities internally and to law enforcement and other entities to ensure the proper and lawful use of its information and resources, in accordance with federal and state law. Such monitoring may result in the
          acquisition, recording and analysis of all data being communicated, transmitted, processed or stored in this system by a user. The State of Ohio complies with state and federal law regarding legally protected confidential
          information but may not treat any other use as private or confidential.
          <br/>
          <br/>
          <b>ANYONE USING THIS SYSTEM EXPRESSLY CONSENTS TO SUCH MONITORING.</b>
          <br/>
          <br/>
          <Link
            href="https://das.ohio.gov/Portals/0/DASDivisions/EmployeeServices/pdf/ApprovedSecurityNotificationforITSystems20181221.pdf"
            target="_blank"
          >Security Notification for IT Systems</Link>
        </>
      )}
      onAgree={handleAgree}
      onDisagree={handleDisagree}
    />
  );
});
