import {IAppRoute} from "../../application/config/IAppRoute";
import {EAlertsManagementRights} from "mhc-server/dist/interfaces";
import {AlertMgtmListPage} from "../pages/AlertMgtmListPage";
import {AlertsListIcon} from "../assets/icons";

export const routeAlertMgtmListPage: IAppRoute<{ presetId: string }> = {
  menuId: '###-alert-mgtm-main-page',
  title: 'Alerts Watchlist',
  subtitle: 'Monitor Alerts',
  description: 'Monitor new and ongoing alerts in real-time.',
  icon: <AlertsListIcon/>,
  routePath: '/alerts-management/watchlist/:presetId?',
  getRoutePath: ({presetId = ""} = {presetId: ''}) =>
    routeAlertMgtmListPage
      .routePath
      .replace(':presetId?', presetId),
  userHasAllRights: [],
  userHasAnyOfRights: [EAlertsManagementRights.ALERT_HANDLING_VIEW],
  render: ({pathParams: {presetId}}) => <AlertMgtmListPage presetId={presetId}/>,
};
