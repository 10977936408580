/**
 * Converts an ISO 3166-1 alpha-2 country code to its corresponding emoji flag.
 *
 * @example
 * getEmojiFlagByCountryISOCode('US'); // Returns '🇺🇸'
 * getEmojiFlagByCountryISOCode('GB'); // Returns '🇬🇧'
 * getEmojiFlagByCountryISOCode('JP'); // Returns '🇯🇵'
 *
 * @see {@link https://en.wikipedia.org/wiki/ISO_3166-1_alpha-2|ISO 3166-1 alpha-2}
 * for a full list of country codes.
 *
 * @note This function assumes the input is a valid ISO 3166-1 alpha-2 country code.
 * It does not validate whether the code corresponds to an actual country.
 */
export const getEmojiFlagByCountryISOCode = (countryCode) => {
    const codePoints = countryCode
        .toUpperCase()
        .split('')
        .map(char => 127397 + char.charCodeAt(0));
    return String.fromCodePoint(...codePoints);
};
