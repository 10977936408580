import { dynaError, } from "dyna-error";
import { encrypt, decrypt, } from "dyna-crypt";
export class ObjectSerializer {
    constructor(config) {
        this.config = config;
        this.setData = (data) => {
            this.data = data;
        };
        this.data = this.config.data;
    }
    serialize() {
        const wrapped = {
            serializerId: 20240618084844,
            data: this.data,
            dataVersion: this.config.dataVersion,
        };
        const stringified = JSON.stringify(wrapped);
        return this.config.encryptionKey
            ? enCodeText(encrypt(stringified, this.config.encryptionKey), this.config.solidString)
            : enCodeText(stringified, this.config.solidString);
    }
    deserialize(rawPayload) {
        if (!rawPayload) {
            return {
                data: this.data,
                error: dynaError({
                    code: 20240618084349,
                    message: 'No payload provided',
                }),
            };
        }
        const payload = (() => {
            if (rawPayload.startsWith('{'))
                return rawPayload;
            const decrypted = decrypt(deCodeText(rawPayload, this.config.solidString), this.config.encryptionKey);
            if (decrypted === undefined)
                return "@@WRONG_KEY";
            return decrypted;
        })();
        if (payload === "@@WRONG_KEY") {
            return {
                data: this.data,
                error: dynaError({
                    code: 20240618090645,
                    message: 'The encrypted payload couldn’t be decrypted because of the wrong key',
                }),
            };
        }
        let parsed = {};
        try {
            parsed = JSON.parse(payload);
        }
        catch (e) {
            return {
                data: this.data,
                error: dynaError({
                    code: 20240618084350,
                    message: 'Cannot parse the payload',
                    parentError: e,
                }),
            };
        }
        if (typeof parsed !== "object") {
            return {
                data: this.data,
                error: dynaError({
                    code: 20240618084351,
                    message: 'Parsed result is not an object',
                    data: { parsed },
                }),
            };
        }
        if (parsed.serializerId !== 20240618084844) {
            return {
                data: this.data,
                error: dynaError({
                    code: 20240618084352,
                    message: 'Parsed object is not recognized by the ObjectSerializer, the origin is unknown',
                    data: { parsed },
                }),
            };
        }
        if (parsed.dataVersion !== this.config.dataVersion) {
            return {
                data: this.data,
                error: dynaError({
                    code: 20240618084353,
                    message: 'The data version of the payload is different, so the schema is not supported by the current implementation',
                    data: { parsed },
                }),
            };
        }
        this.data = parsed.data;
        return {
            data: parsed.data,
            error: null,
        };
    }
}
const splitter = '$x';
const enCodeText = (sourceText, solidString = false) => solidString
    ? encodeURIComponent(sourceText).replace(/%/g, splitter)
    : encodeURIComponent(sourceText);
const deCodeText = (encodedText, solidString = false) => solidString
    ? decodeURIComponent(encodedText.replace(new RegExp(splitter, 'g'), '%'))
    : decodeURIComponent(encodedText);
