import {EWorkZonesManagementRights} from "mhc-server/dist/interfaces";
import {IAppRoute} from "../../application/config/IAppRoute";
import {CIMsMainPage} from "../pages/CIMsMainPage";
import {CIMsSectionIcon} from "../assets/icons";

export const routeCIMsMainPage: IAppRoute = {
  menuId: 'cims',
  icon: <CIMsSectionIcon/>,
  title: 'CIM Tool',
  subtitle: 'Connected Infrastruction Messages Management',
  routePath: '/cims',
  getRoutePath: () => routeCIMsMainPage.routePath,
  userHasAllRights: [],
  userHasAnyOfRights: Object.values(EWorkZonesManagementRights),
  render: () => <CIMsMainPage/>,
};
