import {EDeviceManagementRights} from "mhc-server/dist/interfaces";

import {IAppRoute} from "../../application/config/IAppRoute";
import {DeviceManagementMainPage} from "../pages/DeviceManagementMainPage";
import {DeviceManagementSectionIcon} from "../assets/icons";

export const routeDeviceManagementMainPage: IAppRoute = {
  menuId: '##device-management',
  icon: <DeviceManagementSectionIcon/>,
  title: 'Mercury Device Manager',
  subtitle: 'Setup and Monitor devices',
  routePath: '/device-management',
  getRoutePath: function () {
    return this.routePath;
  },
  userHasAllRights: [],
  userHasAnyOfRights: [
    EDeviceManagementRights.VIEW,
  ],
  render: () => <DeviceManagementMainPage/>,
};
